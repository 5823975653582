import { request } from './index'

/**
 * 获取新手教程列表
 */

export function getCourseList(data) {
  return request({
    method: 'post',
    url: '/course/list',
    data
  })
}
/**
 * 获取新手教程详情
 */

export function getCourseDtl(data) {
  return request({
    method: 'post',
    url: '/course/info',
    data
  })
}
