<template>
  <div class="course">
    <div class="course-title">
      <img src="@/assets/img/l.png" class="course-title-img" alt="logo" />
      <span class="course-title-text">海文达人助手</span>
    </div>
    <div class="courses">
      <div
        class="course-box"
        v-for="item in list"
        :key="item.id"
        @click="jumpCourse(`/courseAll`, item.id)"
      >
        <div class="course-b-text">
          <div class="cbt-title">{{ item.title }}</div>
          <div class="cbt-time">{{ item.createTime }}</div>
        </div>
        <img :src="item.img" class="course-b-img" alt="" />
      </div>
    </div>
    <div
      v-if="loginFlag"
      @click="
        $router.push({ path: '/register', query: { code: $route.query.code } })
      "
    >
      <img src="./img/lh.png" class="jumpRegister" alt="" />
    </div>
  </div>
</template>

<script>
import { getCourseList } from '@/api/course.js'
export default {
  name: 'course',
  data() {
    return {
      list: [],
      loginFlag: false
    }
  },
  async mounted() {
    this.list = await getCourseList()
    this.loginFlag = window.localStorage.getItem('token') == null
  },
  methods: {
    jumpCourse(val, id) {
      this.$router.push({
        path: val,
        query: { id, code: this.$route.query.code }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.course {
  min-height: 100vh;
  padding-top: 120px;
  background: white;
  .course-title {
    position: fixed;
    z-index: 2;
    left: 0px;
    top: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    background: #ff8870;
    padding: 15px 30px;
    .course-title-img {
      width: 70px;
      height: 70px;
    }
    .course-title-text {
      color: white;
      font-size: 34px;
      margin-left: 20px;
    }
  }
  .courses {
    padding: 0px 30px;
    .course-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding: 30px 0px;
      background: white;
      border-bottom: 1px solid #e8e8e8;
      .course-b-text {
        flex: 1;
        text-align: left;
        margin-right: 10px;
        .cbt-title {
          font-size: 26px;
          font-weight: bold;
        }
        .cbt-time {
          margin-top: 30px;
          font-size: 22px;
          color: #999;
        }
      }
      .course-b-img {
        width: 180px;
        height: 135px;
      }
    }
  }
}
</style>
